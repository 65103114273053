@use '../../partials/variables' as *;

.events {
    background-color: $jet;
    width: 100%;
    min-height: 5rem;
    &__container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__buttonContainer {
        width: 100%;
        padding: 1rem 0 1rem;
        min-height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__dropdownButton{
        margin-left: 5rem;
        background-color: $primary;
        color: white;
        font-size: 1.4rem;
	    padding: .5rem;
        width: 13rem;
        
	    cursor: pointer;
	    outline: none;
        border: none;
        box-shadow: 5px 10px 8px  #0e0e0e;
        &:hover {
            background-color: $secondary;
        }
    }
    &__randomIcon{
        background-color: $jet;
        height: 3rem;
        margin-left: 1rem;
        cursor: pointer;
    }
    &__dropdownContent{
        width: 15rem;
        margin: 0 0 1.5rem 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        height: 100%
    }
    &__dropdownItem{
        max-width: 18rem;
        color: white;
        font-size: 1.2rem;
        margin: 0.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
            color: $secondary;
        } 
    }
    &__choiceContainer{
        margin: 0 10rem;
        width: 25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__choiceName{
        color: white;
        margin: 0 0 1rem 0;
        width: 100vw;
        text-align: center;
        -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
    }
    &__choiceDetails{
        color: white;
        font-size: 1.2rem;
        text-align: center;
        padding: 0 2rem;
        width: 100vw;
        text-align: center;
        -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
    }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}