@use '../../partials/variables' as *;

.footer {
    width: 100%;
    background-color: $primary;
    display: flex;
    justify-content: center ;
    &__text {
        margin: 0;
        padding: 0.2rem 0.2rem 0.2rem 1rem;
        font-size: 0.8rem;
        color: cornsilk;
        text-align: center;
    }
    &__link {
        color: cornsilk;
        font-size: 0.8rem;
        margin-top: 2px;
        cursor: pointer;
        
    }
}