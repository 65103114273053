@use '../../partials/variables' as *;
@use '../../partials/mixins' as *;

.hero {
    width: 100%;
    background-color: cornsilk;
    display: flex;
    justify-content: center;
    &__header {
        max-width: 12rem;
        height: auto;
        position: absolute;
        right: 0;
        margin-top: 0.5rem;
        transform: rotate(20deg);
    }
    &__container {
        max-width: 30rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__imageContainer {
        position: relative;
        max-width: 30rem;
        
        overflow: hidden;
    }
    &__btmContainer {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 1rem;
    }
    &__descContainer {
        padding: 0 1rem 0 2rem;
    }
    &__description {
        font-size: 1.1rem;
        margin: 0.5rem 0;
        @include desktopBreakpoint {
            margin: 1rem 0;
        }
    }
    &__image {
        max-width: 100%;
        height: auto;
        margin: 0 1rem 1rem 0;
    }
}